.footer {
  bottom: 20px;
  left: 0;
  width: 100vw;
  padding: 14px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ffffff;

  @media screen and (min-height: 800px) {
    position: absolute;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
    padding: 14px 0 20px;
  }
}

.footer__link {
  position: relative;
  padding: 0 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-size: 16px;

  &::before {
    // content: '';
    position: absolute;
    width: 1px;
    top: -1px;
    height: calc(100% + 4px);
    background-color: #fff;
    right: 0;
  }

  &:last-child::before {
    display: none;
  }
}