* {
  box-sizing: border-box;
}

body {
  max-width: 100%;
  background-color: #2b2b2b;
  color: #e5e5e5;
}

.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 50px 16px;

  @media screen and (max-width: 769px) {
    padding: 30px 16px;
  }
}

.confirmation {
  .response-window {
    background-color: #fff;
    border-radius: 8px;
    padding: 60px 40px;
    width: 100%;
    max-width: 500px;
    margin: 130px auto;

    @media screen and (max-width: 769px) {
      margin: 70px auto;
      padding: 30px 20px;
    }
  }

  .modal {
    border-radius: 8px;
    padding: 50px 40px 0;
    width: 100%;
    max-width: 500px;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    background: #fff;

    @media screen and (max-width: 769px) {
      position: static;
      transform: none;
      padding: 30px 20px 0;
      margin: 20px auto;
    }

    @media screen and (max-height: 700px) {
      position: static;
      transform: none;
      padding: 30px 20px 0;
      margin: 20px auto;
    }

    .form__item {
      margin-right: 0;
    }

    .form__label {
      color: #454545;
    }

    .button {
      margin-right: 0;
      font-size: 18px;
      margin-top: 20px;
    }

    .button-group {
      display: flex;
    }

    .button--cancel {
      background-color: #a7a7a7;
    }
  }

  .response-window__title,
  .modal__title {
    color: #01b1ea;
    font-size: 32px;
    margin-bottom: 18px;
    font-weight: bold;
    text-align: center;
  }

  .response-window__text,
  .modal__text {
    color: #454545;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;

    @media screen and (max-width: 769px){
      font-size: 18px;
    }
  }

  a {
    color: #01b1ea;
  }

  .reset-pass-link {
    color: #01b1ea;
    font-weight: bold;
    border: 0;
    background: none;
    font-size: 18px;
    cursor: pointer;
  }

  .logo {
    margin: 0 auto;
    display: block;
    width: 400px;
    height: 170px;
    max-width: 100%;
    object-fit: contain;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      max-width: 600px;
    }
  }

  .title {
    margin-top: 0;
    font-size: 48px;
    font-weight: bold;
    color: #01b1ea;
  }

  .subtitle {
    font-size: 26px;
    line-height: 1.4;
    font-weight: bold;
    margin: 40px 0;
  }

  p {
    margin: 0;
  }

  .text {
    font-size: 18px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .app-link-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .app-link {
    width: 140px;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .app-link--apple {
    background: #000 url("../../images/apple.png") no-repeat center;
    background-size: 100%;
  }

  .app-link--google {
    background: #000 url("../../images/playmarket.png") no-repeat center;
    background-size: 100%;
  }

  .app-image {
    width: 100%;
    max-width: 400px;
    object-fit: contain;
  }

  .content-title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 40px;
  }

  .list__item {
    margin-bottom: 12px;
    font-size: 18px;
  }

  .form__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 769px) {
      display: block;
    }
  }

  .form__label {
    font-size: 18px;
    color: #fff;
    position: relative;
    display: inline-block;
    margin-bottom: 6px;
  }

  .form__label--required {
    &::before {
      content: '*';
      position: absolute;
      color: #d21d1d;
      top: 0;
      right: -10px;
    }
  }

  .form__item {
    margin-right: 60px;
    position: relative;
    flex: 1;
    margin-bottom: 15px;

    @media screen and (max-width: 769px) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .form__input {
    position: relative;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    display: block;
    padding: 10px 16px;
    font-size: 18px;
    width: 100%;
  }

  .form__input-type-switcher {
    position: absolute;
    top: 36px;
    left: calc(100% - 40px);
    background: url("../../images/eye.svg") no-repeat center;
    width: 35px;
    height: 25px;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  .button {
    display: block;
    border: 0;
    padding: 8px 12px;
    color: white;
    background-color: #01b1ea;
    margin: 40px 0;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.05);
    }

    &:first-child {
      margin-left: auto;
      margin-right: 15px;
    }
  }
}