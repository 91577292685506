* {
  box-sizing: border-box;
}

body {
  min-height: calc(100vh - 30px);
}

.container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 50px 16px;

  @media screen and (max-width: 769px) {
    padding: 30px 16px;
  }
}

.registration {
  .footer {
    @media screen and (max-width: 769px) {
      position: static;
      width: 100%;
      margin-top: 50px;
    }
  }

  .logo {
    margin: 0 auto;
    display: block;
    width: 300px;
    height: 130px;
    object-fit: contain;
    max-width: 100%;
  }

  .title {
    margin-top: 40px;
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    color: #01b1ea;

    @media screen and (max-width: 769px) {
      font-size: 24px;
      margin-top: 15px;
    }
  }

  .form {
    margin: 30px 0;
    max-width: 100%;
  }

  .form__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 769px) {
      display: block;
    }
  }

  .form__label {
    font-size: 18px;
    color: #fff;
    position: relative;
    display: inline-block;
    margin-bottom: 6px;
  }

  .form__label--required {
    &::before {
      content: '*';
      position: absolute;
      color: #d21d1d;
      top: 0;
      right: -10px;
    }
  }

  .form__item {
    margin-right: 60px;
    position: relative;
    flex: 1;
    margin-bottom: 30px;

    @media screen and (max-width: 769px) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .form__input {
    position: relative;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    display: block;
    padding: 10px 16px;
    font-size: 18px;
    width: 100%;
  }

  .form__input-type-switcher {
    position: absolute;
    top: 36px;
    left: calc(100% - 40px);
    background: url("../../images/eye.svg") no-repeat center;
    width: 35px;
    height: 25px;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  .button {
    display: block;
    border: 0;
    padding: 8px 12px;
    min-width: 180px;
    color: white;
    background-color: #01b1ea;
    margin: 40px auto 0;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
  }

  .Toastify__toast {
    border-radius: 4px;
  }
}
